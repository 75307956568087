import React, { useState, useEffect } from 'react';
import { X, Share2, Play, Pause, Facebook, Twitter, Linkedin, Clipboard, Headphones, Link } from 'lucide-react';

interface Recording {
  id: string;
  title: string;
  duration: string;
  date: Date;
  url: string;
  listens: number;
  shares: number;
}

interface RecentRecordingsProps {
  isVisible: boolean;
  onClose: () => void;
  recordings: Recording[];
}

const RecentRecordings: React.FC<RecentRecordingsProps> = ({ isVisible, onClose, recordings }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(null);
  const [currentRecordingId, setCurrentRecordingId] = useState<string | null>(null);
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [selectedRecording, setSelectedRecording] = useState<Recording | null>(null);

  // Prevent page scroll when modal is open
  useEffect(() => {
    if (isVisible || showSharePopup) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isVisible, showSharePopup]);

  // Cleanup audio when component unmounts or when switching audio files
  useEffect(() => {
    return () => {
      currentAudio?.pause();
      setCurrentAudio(null);
      setIsPlaying(false);
    };
  }, [currentAudio]);

  const togglePlay = (recording: Recording) => {
    // Stop current audio if it's already playing
    if (isPlaying && currentRecordingId === recording.id) {
      currentAudio?.pause();
      setIsPlaying(false);
      setCurrentRecordingId(null);
      return;
    }

    // Play new audio
    const newAudio = new Audio(recording.url);
    newAudio.play();
    setCurrentAudio(newAudio);
    setCurrentRecordingId(recording.id);
    setIsPlaying(true);

    newAudio.onended = () => {
      setIsPlaying(false);
      setCurrentRecordingId(null);
    };
  };

  const openSharePopup = (recording: Recording) => {
    setSelectedRecording(recording);
    setShowSharePopup(true);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
      <div className="absolute inset-0 bg-black/70 backdrop-blur-md" onClick={onClose} />

      <div className="relative w-96 bg-black/30 backdrop-blur-md rounded-2xl border border-white/10 overflow-hidden">
        <div className="p-4 border-b border-white/10 flex justify-between items-center">
          <h3 className="text-lg font-semibold text-white">Recent Recordings</h3>
          <button onClick={onClose} className="p-2 hover:bg-white/10 rounded-full transition-colors">
            <X className="w-5 h-5 text-white/60" />
          </button>
        </div>

        <div className="p-4 space-y-4">
          {recordings.map((recording) => (
            <div key={recording.id} className="group bg-white/5 hover:bg-white/10 rounded-xl p-4 transition-all">
              <div className="flex items-center gap-4">
                <button
                  onClick={() => togglePlay(recording)}
                  className="w-10 h-10 flex items-center justify-center bg-purple-500 rounded-full hover:bg-purple-600 transition-colors"
                >
                  {isPlaying && currentRecordingId === recording.id ? (
                    <Pause className="w-5 h-5 text-white" />
                  ) : (
                    <Play className="w-5 h-5 text-white" />
                  )}
                </button>
                <div className="flex-grow">
                  <h4 className="text-white font-medium">{recording.title}</h4>
                  <p className="text-sm text-white/60">
                    {recording.duration} • {recording.date.toLocaleDateString()}
                  </p>
                  <div className="flex gap-4 mt-2 text-xs text-white/70">
                    <span className="flex items-center gap-1">
                      <Headphones className="w-4 h-4" /> {recording.listens} listens
                    </span>
                    <span className="flex items-center gap-1">
                      <Link className="w-4 h-4" /> {recording.shares} shares
                    </span>
                  </div>
                </div>
                <button
                  onClick={() => openSharePopup(recording)}
                  className="p-2 hover:bg-white/10 rounded-full opacity-0 group-hover:opacity-100 transition-all"
                >
                  <Share2 className="w-5 h-5 text-white" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Enhanced Share Popup Modal */}
      {showSharePopup && selectedRecording && (
        <div className="fixed inset-0 z-60 flex items-center justify-center p-4">
          <div className="absolute inset-0 bg-black/80 backdrop-blur-md" onClick={() => setShowSharePopup(false)} />
          <div className="relative bg-white rounded-xl w-full max-w-md p-6 shadow-lg space-y-4">
            <button
              onClick={() => setShowSharePopup(false)}
              className="absolute top-3 right-3 p-2 hover:bg-gray-200 rounded-full"
            >
              <X className="w-5 h-5 text-gray-500" />
            </button>
            <h2 className="text-xl font-bold text-gray-800">Share "{selectedRecording.title}"</h2>
            <p className="text-gray-600 text-sm">
              Choose where you’d like to share this voice memo. Spread your message!
            </p>
            <div className="space-y-3">
              <button className="flex items-center gap-3 w-full py-2 bg-blue-600 text-white rounded-lg justify-center">
                <Facebook className="w-5 h-5" />
                Share on Facebook
              </button>
              <button className="flex items-center gap-3 w-full py-2 bg-sky-500 text-white rounded-lg justify-center">
                <Twitter className="w-5 h-5" />
                Share on Twitter
              </button>
              <button className="flex items-center gap-3 w-full py-2 bg-blue-700 text-white rounded-lg justify-center">
                <Linkedin className="w-5 h-5" />
                Share on LinkedIn
              </button>
              <button
                onClick={() => {
                  navigator.clipboard.writeText(selectedRecording.url);
                  alert('Link copied to clipboard!');
                }}
                className="flex items-center gap-3 w-full py-2 bg-gray-700 text-white rounded-lg justify-center"
              >
                <Clipboard className="w-5 h-5" />
                Copy Link
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecentRecordings;
