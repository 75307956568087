// components/RecordButton.tsx
import React, { useState, useRef, useEffect } from 'react';
import { Info, Mic, MicOff } from 'lucide-react';

interface RecordButtonProps {
  setIsInfoOpen: (value: boolean) => void;
}

export const RecordButton: React.FC<RecordButtonProps> = ({ setIsInfoOpen }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(10);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  // Handle microphone permissions and setup MediaRecorder
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mpeg-3' });
        const audioUrl = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioUrl);
        audio.play();

        // Reset timer
        setRecordingTime(10);
      };

      mediaRecorderRef.current.start();

      // Start countdown timer
      timerRef.current = setInterval(() => {
        setRecordingTime((prevTime) => {
          if (prevTime <= 1) {
            stopRecording();
            return 10;
          }
          return prevTime - 1;
        });
      }, 1000);

      setIsRecording(true);
    } catch (error) {
      console.error('Error accessing microphone:', error);
      alert('Microphone access is required to record audio.');
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop());
    }
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    setIsRecording(false);
  };

  const handleRecordClick = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  // Cleanup on component unmount
  useEffect(() => {
    return () => {
      if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
        mediaRecorderRef.current.stop();
      }
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  return (
    <div className="flex flex-col items-center">
      {/* Record Button */}
      <button
        className="relative group focus:outline-none rounded-full"
        aria-label={isRecording ? 'Stop recording' : 'Press to record'}
        aria-pressed={isRecording}
        onClick={handleRecordClick}
      >
        {/* Visual Indicator */}
        <div
          className={`w-20 h-20 bg-red-500 rounded-full flex items-center justify-center cursor-pointer transition-transform duration-200 transform group-hover:scale-105 ${
            isRecording ? 'bg-red-600 animate-pulse' : 'hover:bg-red-600'
          }`}
        >
          {isRecording ? (
            <MicOff className="w-10 h-10 text-white" />
          ) : (
            <Mic className="w-10 h-10 text-white" />
          )}
        </div>

        {/* Recording Timer */}
        {isRecording && (
          <div className="absolute top-0 right-0 bg-red-700 text-white text-xs px-2 py-1 rounded-bl-full">
            {recordingTime}s
          </div>
        )}

        {/* Hover Tooltip */}
        {!isRecording && (
          <div className="absolute bottom-24 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            <div className="flex items-center bg-gray-800 bg-opacity-90 text-white text-sm px-4 py-2 rounded-full shadow-lg">
              <span className="mr-4">Press to record!</span>
              {/* Info Icon */}
              <button
                onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering the button's onClick
                  setIsInfoOpen(true);
                }}
                className="text-white hover:text-gray-300 focus:outline-none"
                aria-label="Info about recording"
              >
                <Info className="w-4 h-4" />
              </button>
            </div>
          </div>
        )}

        {/* Tooltip when recording */}
        {isRecording && (
          <div className="absolute bottom-24 left-1/2 transform -translate-x-1/2 opacity-100 transition-opacity duration-300">
            <div className="flex items-center bg-gray-800 bg-opacity-90 text-white text-sm px-4 py-2 rounded-full shadow-lg">
              <span className="mr-4">Recording...</span>
              {/* Info Icon */}
              <button
                onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering the button's onClick
                  setIsInfoOpen(true);
                }}
                className="text-white hover:text-gray-300 focus:outline-none"
                aria-label="Info about recording"
              >
                <Info className="w-4 h-4" />
              </button>
            </div>
          </div>
        )}
      </button>
    </div>
  );
};
