import React, { useState } from 'react';
import { X, Send, Coins, Twitter, Facebook, Linkedin, Link2 } from 'lucide-react';

interface BetaAccessModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const BetaAccessModal: React.FC<BetaAccessModalProps> = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [transaction, setTransaction] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSharePopup, setShowSharePopup] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await fetch('https://discord.com/api/webhooks/1303432447203213342/_reA4kVhAQu9gi3oX7oEq55hrhWRAJTYp40riqwMQfWqgi1uBxHICT7dhX3ItKbIO-13', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          content: null,
          embeds: [{
            title: 'New Beta Access Request',
            color: 5814783,
            fields: [
              {
                name: 'Email',
                value: email,
                inline: true
              },
              {
                name: 'Transaction ID',
                value: transaction,
                inline: true
              }
            ],
            timestamp: new Date().toISOString()
          }]
        })
      });

      setEmail('');
      setTransaction('');
      alert('Request submitted successfully!');
      onClose();
    } catch (error) {
      console.error('Error submitting request:', error);
      alert('Error submitting request. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const shareToSocial = (platform: string) => {
    const shareText = `Join Speakify Beta Access - Get early access now! https://speakify.eu/beta`;
    console.log(`Sharing to ${platform}: ${shareText}`);
    // You can add platform-specific share logic here
  };

  const copyLink = () => {
    const linkText = `Join Speakify Beta Access - Get early access now! https://speakify.eu/beta`;
    navigator.clipboard.writeText(linkText)
      .then(() => console.log('Link copied to clipboard'))
      .catch(err => console.error('Failed to copy link', err));
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/70 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-gradient-to-b from-gray-900 to-black border border-white/10 rounded-3xl shadow-2xl max-w-md w-full mx-4 p-6">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-yellow-500 rounded-lg">
              <Coins className="w-6 h-6 text-white" />
            </div>
            <h2 className="text-2xl font-bold text-white">Join Beta Access</h2>
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-white/10 rounded-full transition-colors"
          >
            <X className="w-5 h-5 text-white" />
          </button>
        </div>

        <div className="mb-6">
          <p className="text-gray-300">
            Gain early access to Speakify for 50 USD via Solana. Send payment to:
          </p>
          <div className="mt-2 p-3 bg-gray-800 rounded-lg">
            <code className="text-sm text-gray-300 break-all">
              671eDBfj37agNwa9SkZWy2Ao617Fat9ihFHgsjeg6Dhq
            </code>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-1">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 bg-gray-700 text-white border border-gray-600 rounded-lg focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500"
              required
            />
          </div>
          
          <div>
            <label htmlFor="transaction" className="block text-sm font-medium text-gray-300 mb-1">
              Transaction ID
            </label>
            <input
              type="text"
              id="transaction"
              value={transaction}
              onChange={(e) => setTransaction(e.target.value)}
              className="w-full px-3 py-2 bg-gray-700 text-white border border-gray-600 rounded-lg focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500"
              required
            />
          </div>

          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full bg-gradient-to-r from-yellow-400 to-yellow-600 text-white py-2 rounded-lg flex items-center justify-center gap-2 hover:from-yellow-500 hover:to-yellow-700 transition-colors"
          >
            <Send className="w-4 h-4" />
            {isSubmitting ? 'Submitting...' : 'Submit Request'}
          </button>
        </form>

        <div className="mt-6 flex justify-center">
          <button
            onClick={() => setShowSharePopup(true)}
            className="text-blue-400 underline"
          >
            Share Beta Access
          </button>
        </div>
      </div>

      {showSharePopup && (
        <div className="fixed inset-0 z-[60] flex items-center justify-center p-4">
          <div 
            className="absolute inset-0 bg-black/70 backdrop-blur-md"
            onClick={() => setShowSharePopup(false)}
          />

          <div 
            className="relative w-[400px] transform transition-all scale-100 opacity-100"
            style={{ animation: 'modalPop 0.3s ease-out' }}
          >
            <div 
              className="bg-gradient-to-b from-gray-900 to-black border border-white/10 rounded-3xl overflow-hidden shadow-2xl"
              style={{ boxShadow: '0 0 100px rgba(147, 51, 234, 0.2)' }}
            >
              <div className="bg-gradient-to-r from-purple-600 to-blue-600 px-6 py-6">
                <div className="flex justify-between items-center">
                  <div>
                    <h3 className="text-xl font-bold text-white mb-1">Share Beta Access</h3>
                    <p className="text-sm text-white/70">Choose your platform to share</p>
                  </div>
                  <button
                    onClick={() => setShowSharePopup(false)}
                    className="p-2 hover:bg-white/10 rounded-full transition-colors"
                  >
                    <X className="w-6 h-6 text-white" />
                  </button>
                </div>
              </div>

              <div className="p-6 space-y-6">
                <div className="grid grid-cols-2 gap-4">
                  <button
                    onClick={() => shareToSocial('twitter')}
                    className="group relative overflow-hidden rounded-2xl bg-gradient-to-br from-blue-400 to-blue-600 p-[1px] transition-all hover:scale-[1.02]"
                  >
                    <div className="relative bg-black rounded-2xl p-4 flex items-center gap-3">
                      <Twitter className="w-5 h-5 text-white" />
                      <span className="text-white font-medium">Twitter</span>
                    </div>
                  </button>

                  <button
                    onClick={() => shareToSocial('facebook')}
                    className="group relative overflow-hidden rounded-2xl bg-gradient-to-br from-blue-600 to-blue-800 p-[1px] transition-all hover:scale-[1.02]"
                  >
                    <div className="relative bg-black rounded-2xl p-4 flex items-center gap-3">
                      <Facebook className="w-5 h-5 text-white" />
                      <span className="text-white font-medium">Facebook</span>
                    </div>
                  </button>

                  <button
                    onClick={() => shareToSocial('linkedin')}
                    className="group relative overflow-hidden rounded-2xl bg-gradient-to-br from-blue-700 to-blue-900 p-[1px] transition-all hover:scale-[1.02]"
                  >
                    <div className="relative bg-black rounded-2xl p-4 flex items-center gap-3">
                      <Linkedin className="w-5 h-5 text-white" />
                      <span className="text-white font-medium">LinkedIn</span>
                    </div>
                  </button>

                  <button
                    onClick={copyLink}
                    className="group relative overflow-hidden rounded-2xl bg-gradient-to-br from-purple-600 to-purple-800 p-[1px] transition-all hover:scale-[1.02]"
                  >
                    <div className="relative bg-black rounded-2xl p-4 flex items-center gap-3">
                      <Link2 className="w-5 h-5 text-white" />
                      <span className="text-white font-medium">Copy Link</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <style jsx>{`
        @keyframes modalPop {
          0% {
            transform: scale(0.95);
            opacity: 0;
          }
          100% {
            transform: scale(1);
            opacity: 1;
          }
        }
      `}</style>
    </div>
  );
};

export default BetaAccessModal;
