// App.tsx
import React, { useState } from 'react';
import { RecordButton } from './components/RecordButton';
import {
  Volume2,
  Sparkles,
  Home,
  DollarSign,
  Info,
  Phone,
  FileText,
  Shield,
  Tag,
  Share2,
  Heart,
  X,
  List,
} from 'lucide-react';
import BetaAccessModal from './components/BetaAccessModal';
import RecentRecordings from './components/RecentRecordings';
import Screenshots from './components/Screenshots';
import FeaturePopup from './components/FeaturePopup';
import SupportTicketForm from './components/SupportTicketForm';
import VoiceSpectrum from './components/VoiceSpectrum';
import EarlyAccessPayment from './components/EarlyAccessPayment';

export function App() {
  const [isBetaOpen, setIsBetaOpen] = useState(false);
  const [showRecordings, setShowRecordings] = useState(false);
  const [isHomeOpen, setIsHomeOpen] = useState(false);
  const [isFeaturesOpen, setIsFeaturesOpen] = useState(false);
  const [isPricingOpen, setIsPricingOpen] = useState(false);
  const [isContactOpen, setIsContactOpen] = useState(false);

  const [isVoiceRecordingOpen, setIsVoiceRecordingOpen] = useState(false);
  const [isSpectrumOpen, setIsSpectrumOpen] = useState(false);
  const [isSocialSharingOpen, setIsSocialSharingOpen] = useState(false);
  const [isPremiumFeaturesOpen, setIsPremiumFeaturesOpen] = useState(false);

  const [isDocumentationOpen, setIsDocumentationOpen] = useState(false);
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false);
  const [isTermsOfServiceOpen, setIsTermsOfServiceOpen] = useState(false);
  const [isSupportOpen, setIsSupportOpen] = useState(false);

  const [isScreenshotOpen, setIsScreenshotOpen] = useState(false);
  const [selectedScreenshot, setSelectedScreenshot] = useState<string | null>(null);

  const [isInfoOpen, setIsInfoOpen] = useState(false);

  const openScreenshotPopup = (screenshotUrl: string) => {
    setSelectedScreenshot(screenshotUrl);
    setIsScreenshotOpen(true);
  };

  const sampleRecordings = [
    {
      id: '1',
      title: 'Morning Thoughts',
      duration: '1:30',
      date: new Date(),
      url: '/audio/morning-thoughts.mp3',
      listens: 105,
      shares: 32,
    },
    {
      id: '2',
      title: 'Weekend Vibes',
      duration: '2:15',
      date: new Date(),
      url: '/audio/weekend-vibes.mp3',
      listens: 203,
      shares: 78,
    },
    {
      id: '3',
      title: 'Inspirational Quote',
      duration: '0:45',
      date: new Date(),
      url: '/audio/inspirational-quote.mp3',
      listens: 150,
      shares: 47,
    },
    {
      id: '4',
      title: 'Random Thoughts',
      duration: '1:05',
      date: new Date(),
      url: '/audio/random-thoughts.mp3',
      listens: 97,
      shares: 22,
    },
    {
      id: '5',
      title: 'Daily Motivation',
      duration: '1:20',
      date: new Date(),
      url: '/audio/daily-motivation.mp3',
      listens: 320,
      shares: 100,
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#1a1a2e] via-[#16213e] to-[#0f3460] flex flex-col relative">
      <div className="bg-black/20 backdrop-blur-sm text-white py-4 sticky top-0 z-50 border-b border-white/10">
        <div className="container mx-auto px-4 flex items-center justify-between">
          <div className="flex items-center gap-4">
            <img src="/logo.png" alt="Speakify Logo" className="w-10 h-10" />
            <div className="flex items-center gap-2">
              <Sparkles className="w-5 h-5 text-yellow-400 animate-pulse" />
              <span className="text-lg font-medium">BETA: Currently under development</span>
            </div>
          </div>
          <button
            onClick={() => setIsBetaOpen(true)}
            className="px-6 py-2 bg-gradient-to-r from-yellow-400 to-yellow-600 text-white rounded-full font-medium hover:from-yellow-500 hover:to-yellow-700 transition-all transform hover:scale-105"
          >
            Get Early Access
          </button>
        </div>
      </div>

      <header className="bg-black/20 backdrop-blur-sm border-b border-white/10">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <h1 className="text-2xl font-bold text-white flex items-center gap-2">
            <img src="/logo.png" alt="Speakify Logo" className="w-8 h-8" />
            Speakify
          </h1>
          <nav className="flex gap-6">
            <button onClick={() => setIsHomeOpen(true)} className="text-white hover:text-purple-500 transition-colors">
              Home
            </button>
            <button onClick={() => setIsFeaturesOpen(true)} className="text-white hover:text-purple-500 transition-colors">
              Features
            </button>
            <button onClick={() => setIsPricingOpen(true)} className="text-white hover:text-purple-500 transition-colors">
              Pricing
            </button>
            <button onClick={() => setIsContactOpen(true)} className="text-white hover:text-purple-500 transition-colors">
              Contact
            </button>
            <button
              onClick={() => setShowRecordings(true)}
              className="text-white hover:text-purple-500 transition-colors relative"
              aria-label="Recent Recordings"
            >
              <List className="w-6 h-6" />
              {sampleRecordings.length > 0 && (
                <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full px-1">
                  {sampleRecordings.length}
                </span>
              )}
            </button>
          </nav>
        </div>
      </header>

      <main className="container mx-auto px-4 py-12 flex-grow relative">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold text-white mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-blue-400">
            Share Your Voice
          </h2>
          <p className="text-lg text-white/60 max-w-2xl mx-auto">
            Experience social media reimagined - where your voice is all that matters. 
            No images, no text, just authentic voice connections.
          </p>
        </div>

        <div className="max-w-4xl mx-auto relative">
          <div className="w-full bg-black/30 backdrop-blur-md rounded-2xl p-8 border border-white/10 shadow-lg glow-effect">
            <VoiceSpectrum isRecording={showRecordings} />
          </div>
          <RecentRecordings isVisible={showRecordings} onClose={() => setShowRecordings(false)} recordings={sampleRecordings} />
        </div>

        <Screenshots onScreenshotClick={openScreenshotPopup} />
      </main>

      <div className="fixed bottom-4 left-4 z-50">
        <RecordButton setIsInfoOpen={setIsInfoOpen} />
      </div>

      <footer className="bg-black/30 backdrop-blur-sm border-t border-white/10 mt-20 py-12 px-4 relative">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-xl font-bold text-white flex items-center gap-2">
              <img src="/logo.png" alt="Speakify Logo" className="w-6 h-6" />
              Speakify
            </h3>
            <p className="text-white/60 text-sm mt-4 max-w-md">
              Revolutionizing social media through the power of voice. Speakify empowers users to share quick thoughts, listen to others' stories, and connect in meaningful, voice-driven interactions.
              Join us in creating a world where voices connect communities, inspire change, and celebrate individuality.
            </p>
          </div>

          <div>
            <h4 className="text-white font-semibold mb-4">Features</h4>
            <ul className="space-y-2 text-white/60">
              <li onClick={() => setIsVoiceRecordingOpen(true)} className="cursor-pointer hover:text-white">Voice Recording</li>
              <li onClick={() => setIsSpectrumOpen(true)} className="cursor-pointer hover:text-white">Real-time Spectrum</li>
              <li onClick={() => setIsSocialSharingOpen(true)} className="cursor-pointer hover:text-white">Social Sharing</li>
              <li onClick={() => setIsPremiumFeaturesOpen(true)} className="cursor-pointer hover:text-white">Premium Features</li>
            </ul>
          </div>

          <div>
            <h4 className="text-white font-semibold mb-4">Resources</h4>
            <ul className="space-y-2 text-white/60">
              <li onClick={() => setIsDocumentationOpen(true)} className="cursor-pointer hover:text-white">Documentation</li>
              <li onClick={() => setIsPrivacyPolicyOpen(true)} className="cursor-pointer hover:text-white">Privacy Policy</li>
              <li onClick={() => setIsTermsOfServiceOpen(true)} className="cursor-pointer hover:text-white">Terms of Service</li>
              <li onClick={() => setIsSupportOpen(true)} className="cursor-pointer hover:text-white">Support</li>
            </ul>
          </div>
        </div>

        <div className="absolute bottom-2 right-4 text-white/70 text-sm flex items-center gap-1">
          Made with <Heart className="w-4 h-4 text-red-500 animate-pulse" /> by Speakify
        </div>
      </footer>

      {isScreenshotOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 overflow-hidden">
          <div className="absolute inset-0 bg-black/80 backdrop-blur-md" onClick={() => setIsScreenshotOpen(false)} />
          <div className="relative bg-white rounded-xl max-w-3xl w-full shadow-lg p-4 overflow-auto">
            <button onClick={() => setIsScreenshotOpen(false)} className="absolute top-3 right-3 p-2 hover:bg-gray-200 rounded-full">
              <X className="w-6 h-6 text-gray-500" />
            </button>
            <img src={selectedScreenshot || ''} alt="Screenshot" className="rounded-md w-full" />
          </div>
        </div>
      )}

      {isInfoOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 overflow-hidden">
          <div className="absolute inset-0 bg-black/80 backdrop-blur-md" onClick={() => setIsInfoOpen(false)} />
          <div className="relative bg-white rounded-xl max-w-sm w-full shadow-lg p-6">
            <button onClick={() => setIsInfoOpen(false)} className="absolute top-3 right-3 p-2 hover:bg-gray-200 rounded-full">
              <X className="w-6 h-6 text-gray-500" />
            </button>
            <h3 className="text-xl font-semibold mb-4">Recording Information</h3>
            <p className="text-gray-700">
              - You can record audio clips up to <strong>10 seconds</strong> long.
              <br />
              - Share your voice recordings seamlessly on social media platforms.
            </p>
          </div>
        </div>
      )}

      <FeaturePopup
        isOpen={isHomeOpen}
        onClose={() => setIsHomeOpen(false)}
        title="Home"
        description="Welcome to Speakify, where your voice connects you to the world in a new, authentic way."
        icon={<Home className="w-6 h-6 text-white" />}
      />
      <FeaturePopup
        isOpen={isFeaturesOpen}
        onClose={() => setIsFeaturesOpen(false)}
        title="Features"
        description="Discover the features that make Speakify unique. From voice recording to real-time sharing and spectrum visuals, Speakify offers tools for meaningful connections."
        icon={<Volume2 className="w-6 h-6 text-white" />}
      />
      <FeaturePopup
        isOpen={isPricingOpen}
        onClose={() => setIsPricingOpen(false)}
        title="Pricing"
        description="Affordable pricing options that unlock advanced features for your voice-sharing experience."
        icon={<DollarSign className="w-6 h-6 text-white" />}
      />
      <FeaturePopup
        isOpen={isContactOpen}
        onClose={() => setIsContactOpen(false)}
        title="Contact"
        description="Get in touch with us for questions, support, or feedback. We're here to help!"
        icon={<Phone className="w-6 h-6 text-white" />}
      />

      <FeaturePopup
        isOpen={isVoiceRecordingOpen}
        onClose={() => setIsVoiceRecordingOpen(false)}
        title="Voice Recording"
        description="Record high-quality voice memos effortlessly."
        icon={<Volume2 className="w-6 h-6 text-white" />}
      />
      <FeaturePopup
        isOpen={isSpectrumOpen}
        onClose={() => setIsSpectrumOpen(false)}
        title="Real-time Spectrum"
        description="See your voice in real-time visuals."
        icon={<Volume2 className="w-6 h-6 text-white" />}
      />
      <FeaturePopup
        isOpen={isSocialSharingOpen}
        onClose={() => setIsSocialSharingOpen(false)}
        title="Social Sharing"
        description="Easily share your recordings on social media."
        icon={<Share2 className="w-6 h-6 text-white" />}
      />
      <FeaturePopup
        isOpen={isPremiumFeaturesOpen}
        onClose={() => setIsPremiumFeaturesOpen(false)}
        title="Premium Features"
        description="Access exclusive features with Speakify Premium."
        icon={<DollarSign className="w-6 h-6 text-white" />}
      />

      <FeaturePopup
        isOpen={isDocumentationOpen}
        onClose={() => setIsDocumentationOpen(false)}
        title="Documentation"
        description="Learn more about how to use Speakify."
        icon={<FileText className="w-6 h-6 text-white" />}
      />
      <FeaturePopup
        isOpen={isPrivacyPolicyOpen}
        onClose={() => setIsPrivacyPolicyOpen(false)}
        title="Privacy Policy"
        description="Read our privacy policy to know how we protect your data."
        icon={<Shield className="w-6 h-6 text-white" />}
      />
      <FeaturePopup
        isOpen={isTermsOfServiceOpen}
        onClose={() => setIsTermsOfServiceOpen(false)}
        title="Terms of Service"
        description="Our terms and conditions of using Speakify."
        icon={<Tag className="w-6 h-6 text-white" />}
      />

      <BetaAccessModal isOpen={isBetaOpen} onClose={() => setIsBetaOpen(false)}>
        <EarlyAccessPayment />
      </BetaAccessModal>

      <SupportTicketForm
        isOpen={isSupportOpen}
        onClose={() => setIsSupportOpen(false)}
      />
    </div>
  );
}
