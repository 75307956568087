// components/VoiceSpectrum.tsx
import React from 'react';

interface VoiceSpectrumProps {
  isRecording: boolean;
}

const VoiceSpectrum: React.FC<VoiceSpectrumProps> = ({ isRecording }) => {
  const totalBars = 60; // Number of bars in the spectrum

  return (
    <div className="flex justify-center items-end w-full h-24 overflow-hidden">
      {[...Array(totalBars)].map((_, index) => {
        // Calculate hue for vibrant colors, spreading across the color wheel multiple times for variety
        const hue = (index * 6) % 360; // Adjust multiplier for color distribution

        return (
          <div
            key={index}
            className="rounded-full transition-all ease-in-out flex-1 mx-0.5"
            style={{
              height: `${Math.random() * 50 + 20}px`, // Random height for wave effect
              backgroundColor: `hsl(${hue}, 100%, 50%)`, // Vibrant color
              animation: isRecording
                ? `wave 0.8s ease-in-out ${index * 0.02}s infinite` // Faster wave animation
                : 'pulse-static 1s ease-in-out infinite', // Subtle pulse when not recording
            }}
          />
        );
      })}

      {/* Keyframe animations for pulsing and wave effect */}
      <style jsx>{`
        @keyframes wave {
          0%, 100% {
            transform: scaleY(1);
          }
          50% {
            transform: scaleY(1.8);
          }
        }

        @keyframes pulse-static {
          0%, 100% {
            transform: scaleY(1);
          }
          50% {
            transform: scaleY(1.3);
          }
        }
      `}</style>
    </div>
  );
};

export default VoiceSpectrum;
