// components/EarlyAccessPayment.tsx
import React, { useState } from 'react';
import { Copy, Check } from 'lucide-react';

const paymentMethods = [
  {
    name: 'Bitcoin (BTC)',
    address: '3JBSt7aY8QZq2bjoBfrWEVfHCGbi3ZQTPp',
    icon: '/btc-icon.png',
  },
  {
    name: 'Ethereum (ETH)',
    address: '0x539351f0e9d8da9c1582d5d8a6949af8abcdc3e2',
    icon: '/eth-icon.png',
  },
  {
    name: 'Binance Smart Chain (BNB)',
    address: '0x539351f0e9d8da9c1582d5d8a6949af8abcdc3e2',
    icon: '/bnb-icon.png',
  },
];

const EarlyAccessPayment: React.FC = () => {
  const [copied, setCopied] = useState<string | null>(null);

  const handleCopy = (address: string) => {
    navigator.clipboard.writeText(address);
    setCopied(address);
    setTimeout(() => setCopied(null), 2000);
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold mb-4">Choose Payment Method</h2>
      <div className="space-y-4">
        {paymentMethods.map((method) => (
          <div key={method.name} className="flex items-center justify-between p-4 bg-gray-800 rounded-lg">
            <div className="flex items-center gap-4">
              <img src={method.icon} alt={`${method.name} icon`} className="w-8 h-8" />
              <div>
                <h3 className="text-lg font-medium text-white">{method.name}</h3>
                <p className="text-sm text-gray-400 break-all">{method.address}</p>
              </div>
            </div>
            <button
              onClick={() => handleCopy(method.address)}
              className="flex items-center gap-2 px-3 py-1 bg-purple-600 text-white rounded hover:bg-purple-700 transition"
            >
              {copied === method.address ? <Check className="w-4 h-4" /> : <Copy className="w-4 h-4" />}
              {copied === method.address ? 'Copied' : 'Copy'}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EarlyAccessPayment;
