import React from 'react';

const Screenshots = () => {
  const screenshots = [
    {
      id: 1,
      title: "Voice-First Social Hub",
      description: "Discover trending voice memos, connect with friends, and explore a new way of social interaction. Follow creators, build your audience, and express yourself through voice.",
      imageUrl: "https://i.imgur.com/Ti7T0aO.png"
    },
    {
      id: 2,
      title: "Premium Recording Studio",
      description: "Create, edit, and share high-quality voice memos. Join our growing community of voice creators and let your story be heard. Features voice filters, background music, and more.",
      imageUrl: "https://i.imgur.com/Sw8SDWG.png"
    }
  ];

  return (
    <div className="mt-20">
      <h2 className="text-2xl font-bold text-white text-center mb-8">
        Experience Speakify
      </h2>
      <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto px-4">
        {screenshots.map((screenshot) => (
          <div
            key={screenshot.id}
            className="group relative overflow-hidden rounded-3xl border border-white/10 bg-black/20 backdrop-blur-sm transition-all hover:scale-[1.02] hover:shadow-2xl hover:shadow-purple-500/20"
          >
            <div className="aspect-[16/9] overflow-hidden">
              <img
                src={screenshot.imageUrl}
                alt={screenshot.title}
                className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
              />
            </div>
            <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <div className="absolute bottom-0 left-0 right-0 p-8">
                <h3 className="text-2xl font-bold text-white mb-3 bg-gradient-to-r from-purple-400 to-blue-400 bg-clip-text text-transparent">
                  {screenshot.title}
                </h3>
                <p className="text-white/80 text-lg leading-relaxed">
                  {screenshot.description}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Screenshots;