import React, { useState, useEffect } from 'react';
import { X, Send } from 'lucide-react';

interface SupportTicketFormProps {
  isOpen: boolean;
  onClose: () => void;
}

const SupportTicketForm: React.FC<SupportTicketFormProps> = ({ isOpen, onClose }) => {
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const [tags, setTags] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ticketId, setTicketId] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);

  const tagsList = ['Bug', 'Feature Request', 'Feedback', 'Other'];

  // Generate a unique ticket ID when the form opens
  useEffect(() => {
    if (isOpen) {
      const uniqueId = `TICKET-${Math.random().toString(36).substr(2, 9).toUpperCase()}`;
      setTicketId(uniqueId);
    }
  }, [isOpen]);

  const handleTagClick = (tag: string) => {
    setTags((prev) => (prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag]));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    const ticket = { ticketId, title, email, description, tags, timestamp: new Date().toISOString() };

    try {
      await fetch('https://discord.com/api/webhooks/1303695605914271827/DnDzSW82MQFCgU2LbVLrSm3MGBYvgpfx36Pkf-tYa0DdF2mZX4YbisjNlYEBFkaCcrlb', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          content: null,
          embeds: [
            {
              title: 'New Support Ticket',
              color: 5814783,
              fields: [
                { name: 'Ticket ID', value: ticket.ticketId, inline: true },
                { name: 'Title', value: ticket.title, inline: true },
                { name: 'Email', value: ticket.email, inline: true },
                { name: 'Description', value: ticket.description, inline: false },
                { name: 'Tags', value: ticket.tags.join(', ') || 'None', inline: true },
                { name: 'Date', value: new Date().toLocaleString(), inline: true },
              ],
              timestamp: ticket.timestamp,
            },
          ],
        }),
      });
      setTitle('');
      setEmail('');
      setDescription('');
      setTags([]);
      setShowConfirmation(true);
    } catch (error) {
      console.error('Error submitting ticket:', error);
      alert('Failed to submit the ticket. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <>
      {/* Main Support Ticket Form */}
      <div className="fixed inset-0 bg-black/60 flex items-center justify-center z-50 p-4">
        <div className="relative bg-white rounded-xl w-full max-w-lg p-6 shadow-lg">
          <button onClick={onClose} className="absolute top-3 right-3 p-2 hover:bg-gray-200 rounded-full">
            <X className="w-5 h-5 text-gray-500" />
          </button>
          <h2 className="text-xl font-bold mb-4">Submit a Support Ticket</h2>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">Title</label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                required
              />
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                required
              />
            </div>

            <div>
              <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">Description</label>
              <textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                rows={4}
                required
              ></textarea>
            </div>

            <div>
              <p className="text-sm font-medium text-gray-700 mb-2">Tags</p>
              <div className="flex gap-2 flex-wrap">
                {tagsList.map((tag) => (
                  <button
                    key={tag}
                    type="button"
                    onClick={() => handleTagClick(tag)}
                    className={`px-3 py-1 rounded-lg border transition-all ${tags.includes(tag) ? 'bg-blue-500 text-white' : 'border-gray-300'}`}
                  >
                    {tag}
                  </button>
                ))}
              </div>
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full bg-gradient-to-r from-blue-500 to-blue-600 text-white py-2 rounded-lg flex items-center justify-center gap-2 hover:from-blue-600 hover:to-blue-700 transition-all"
            >
              <Send className="w-5 h-5" />
              {isSubmitting ? 'Submitting...' : 'Submit Ticket'}
            </button>
          </form>
        </div>
      </div>

      {/* Confirmation Popup */}
      {showConfirmation && (
        <div className="fixed inset-0 bg-black/60 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg shadow-lg p-6 text-center max-w-xs w-full">
            <button onClick={() => setShowConfirmation(false)} className="absolute top-3 right-3 p-2 hover:bg-gray-200 rounded-full">
              <X className="w-5 h-5 text-gray-500" />
            </button>
            <h2 className="text-xl font-bold text-green-600 mb-4">Ticket Submitted!</h2>
            <p className="text-gray-700">Your ticket has been submitted successfully.</p>
            <p className="text-gray-700 mt-2">Ticket ID:</p>
            <p className="text-gray-900 font-semibold">{ticketId}</p>
            <button
              onClick={() => {
                setShowConfirmation(false);
                onClose();
              }}
              className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SupportTicketForm;
